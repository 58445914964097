import {FC, useContext, useState} from "react";
import {
    Divider,
    Grid,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import {AssetType} from "../../../../../../../utils/sessionDataLoader";
import {UserContext} from "../../../../../../../context/user-context";

export interface UserAssetProps {
    data: AssetType,
}

export const UserAsset: FC<UserAssetProps> = ({data}) => {
    const [open, setOpen] = useState<boolean>(false);
    const {user} = useContext(UserContext);

    return (
        <ListItem sx={{backgroundColor: "#340b64CC", borderRadius: "8px", marginBottom: "16px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  {data.owner === user?.email ?
                    <ListItemButton alignItems="flex-start" onClick={() => setOpen(!open)}>
                        <KeyboardArrowDown
                            sx={{
                                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '0.2s',
                            }}
                        />
                        <ListItemText
                            primary={"Asset : " + data.display}
                            secondary={"Manage access"}
                            secondaryTypographyProps={{
                                noWrap: true,
                                lineHeight: '16px',
                                color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                            }}
                            sx={{ my: 0 }}
                        />
                    </ListItemButton> :
                    <ListItemText
                      primary={"Asset : " + data.display}
                      sx={{ my: 0 }}
                    />
                  }
                  <Divider/>
                </Grid>
                {open &&
                    <>
                    </>
                }
            </Grid>
        </ListItem>
    )
}