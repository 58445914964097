import React, {FC, useContext, useEffect, useState} from "react";
import {AssetType} from "../../../../../../utils/sessionDataLoader";
import {Box, Button, Divider, Grid, Link, List, Paper, Typography} from "@mui/material";
import {UserAsset} from "./components/UserAsset";
import {UserContext} from "../../../../../../context/user-context";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export interface AssetManageBoardProps {
  userAssets: AssetType[],
}


export const AssetManageBoard: FC<AssetManageBoardProps> = ({userAssets}) => {
  const {user} = useContext(UserContext)

  const [ownedAssets, setOwnedAssets] = useState<AssetType[]>([]);
  const [otherAssets, setOtherAssets] = useState<AssetType[]>([]);

  useEffect(() => {
    if (user) {
      const newOwnedAssets: AssetType[] = []
      const newOtherAssets: AssetType[] = []
      userAssets.forEach((asset) => {
        if (asset.owner === user.email) {
          newOwnedAssets.push(asset)
        } else {
          newOtherAssets.push(asset)
        }
      })
      setOwnedAssets(newOwnedAssets)
      setOtherAssets(newOtherAssets)
    }
  }, [user, userAssets]);

  return (
    <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
      <Grid container spacing={2} sx={{textAlign: 'center'}} >
        <Grid item xs={12} md={9}>
          <Grid container spacing={2} sx={{textAlign: 'center'}} >
            <Grid item xs={12}>
              <Paper variant="outlined">
                <Typography variant="h5">Your asset</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" sx={{maxHeight: 450, overflow: 'auto', padding: "16px"}}>
                <List sx={{width: "100%"}}>
                  {ownedAssets.map((asset: AssetType) => <UserAsset
                    key={asset.internalLabel}
                    data={asset}
                  />)}
                </List>
                <Link href={"/account/assets/create"} sx={{color: 'inherit', textDecoration: 'inherit', paddingLeft: -1}}>
                  <Button sx={{padding: 0, paddingRight: 1, marginRight: 1, paddingLeft: 1, minWidth: "128px", height: "100%", alignItems: "center", justifyContent: "space-around"}} variant="contained" component="label" startIcon={<AddCircleIcon/>}>
                    Add Asset
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: "-1px", marginTop: 1, marginBottom: 1}} />
        <Grid item xs={12} md={3}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Typography variant="h5">Asset shared with you</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" sx={{maxHeight: 450, overflow: 'auto', padding: "16px"}}>
              <List sx={{width: "100%"}}>
                {otherAssets.map((asset: AssetType) => <UserAsset
                  key={asset.internalLabel}
                  data={asset}
                />)}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}