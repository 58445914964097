import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {AssetCreateBoard} from "./components/asset_create_board/AssetCreateBoard";
import {AssetManageBoard} from "./components/asset_manage_board/AssetManageBoard";
import {NotFoundBoard} from "../../../not_found_board/NotFoundBoard";
import {Route, Routes} from "react-router-dom";
import {AssetType, loadAssetData} from "../../../../utils/sessionDataLoader";
import {UserContext} from "../../../../context/user-context";


export const AssetsBoard: FC = () => {
  const [userAssets, setUserAssets] = useState<AssetType[]>([]);
  const {user} = useContext(UserContext);

  const refreshAssets = useCallback((forceReload: boolean) => {
    if (user) {
      loadAssetData(user, () => {}, setUserAssets, () => {}, false, forceReload);
    }
  }, [user])

  useEffect(() => {
    refreshAssets(false)
  }, [refreshAssets]);

    return (
      <Routes>
        <Route path="/" element={<AssetManageBoard userAssets={userAssets}/>} />
        <Route path="create" element={<AssetCreateBoard userAssets={userAssets} refreshAssets={refreshAssets}/>} />
        <Route path="*" element={<NotFoundBoard/>} />
      </Routes>
    )
}