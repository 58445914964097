import React, {FC} from "react";
import {
    Box, Typography,
} from "@mui/material";


export const PaymentBoard: FC = () => {
    return (
        <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
            <Typography>Need more token ? Please send us a mail at contact@dynalips.com</Typography>
        </Box>
    )
}