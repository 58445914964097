import React, {FC, useContext} from 'react';
import {useEffect, useState} from "react";
import {PlaygroundContext} from "../../../../../../../context/playground-context";
import {AssetType, loadAssetData} from "../../../../../../../utils/sessionDataLoader";
import {
    searchDataFromLabel,
    SelectWithLoader
} from "../../../../../../../utils/components/SelectWithLoader";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Box, Button, Link} from "@mui/material";


interface AssetSelectProps {
    requestData: FormData,
    currentAsset: AssetType | undefined,
    setCurrentAsset: (newAsset: AssetType | undefined) => void,
    preferredAsset: string,
}

export const AssetSelect: FC<AssetSelectProps> = ({requestData, currentAsset, setCurrentAsset, preferredAsset}) => {
    const [assets, setAssets] = useState<AssetType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const {playgroundEnabled} = useContext(PlaygroundContext);

    useEffect(() => {
        if (playgroundEnabled) {
            setCurrentAsset(searchDataFromLabel("metahuman", assets));
        }
    }, [playgroundEnabled, assets, requestData, setCurrentAsset]);

    useEffect(() => {
        if (currentAsset) {
            requestData.set("asset", currentAsset.internalLabel)
        }
    }, [currentAsset, requestData]);

    useEffect(() => {
        if (preferredAsset && assets.length > 0) {
            setCurrentAsset(searchDataFromLabel(preferredAsset, assets));
        }
    }, [preferredAsset, assets, setCurrentAsset])


    return (
      <Box sx={{display: "flex", flexDirection: "row"}}>
          <Link href={"/account/assets/create"} sx={{color: 'inherit', textDecoration: 'inherit', paddingLeft: -1}}>
              <Button sx={{padding: 0, paddingRight: 1, marginRight: 1, paddingLeft: 1, minWidth: "128px", height: "100%", alignItems: "center", justifyContent: "space-around"}} variant="contained" component="label" startIcon={<AddCircleIcon/>}>
                  Add Asset
              </Button>
          </Link>
          <SelectWithLoader id={"asset"} disabled={playgroundEnabled} dataLoaderFunction={loadAssetData}
                            data={assets} setData={setAssets} currentData={currentAsset} setCurrentData={setCurrentAsset}
                            loading={loading} setLoading={setLoading} replaceCurrentOnLoad/>
      </Box>
    )
}