import {FC, useContext} from "react";
import {
  Box, Divider, Stack, capitalize, Grid, Paper, Typography,
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {UserContext} from "../../../../context/user-context";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import {InfoForm} from "./components/InfoForm";

export const InfoBoard: FC = () => {
  const {user} = useContext(UserContext);
  const columnSpace = user?.perm !== "USER" ? 3 : 4;

  return (
    <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
      <Grid container spacing={2} sx={{textAlign: 'left', marginBottom: 1}} >
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{textAlign: 'center'}} >
            <Typography variant="h5">Account infos</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={columnSpace} >
          <Stack direction="row" alignItems="center" gap={1}>
            <AccountCircleIcon/>
            <Typography variant="h6">Login</Typography>
          </Stack>
          <Typography>{user?.email}</Typography>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: "-1px", marginTop: 1, marginBottom: 1}} />
        {user?.perm !== "USER" ?
          <>
            <Grid item xs={12} md={columnSpace}>
              <Stack direction="row" alignItems="center" gap={1}>
                <SecurityIcon/>
                <Typography variant="h6">Access type</Typography>
              </Stack>
              <Typography>{capitalize(user ? user.perm.toLowerCase() : "")}</Typography>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: "-1px", marginTop: 1, marginBottom: 1}} />
          </>
          : <></>}
        <Grid item xs={12} md={columnSpace}>
          <Stack direction="row" alignItems="center" gap={1}>
            <CreditCardIcon/>
            <Typography variant="h6">Plan</Typography>
          </Stack>
          <Typography>{capitalize(user ? user.plan.toLowerCase() : "")}</Typography>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: "-1px", marginTop: 1, marginBottom: 1}} />
        <Grid item xs={12} md={columnSpace}>
          <Stack direction="row" alignItems="center" gap={1}>
            <GroupsIcon/>
            <Typography variant="h6">Groups</Typography>
          </Stack>
          <Typography>{user?.groups.join(',')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider orientation="horizontal" variant="middle" flexItem sx={{marginBottom: 1}} />
          <Accordion sx={{
            backgroundColor: "transparent",
            backgroundImage: "none",
            boxShadow: 0
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: 0, marginBottom: 0}}>
                <InfoIcon/>
                <Typography variant="h6">Personal infos</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <InfoForm/>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  )
}