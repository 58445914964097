import React, {FC, useCallback, useState} from "react";
import {Box, Grid, ListItem, Typography} from "@mui/material";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {
  FileInput
} from "../../../../../../animation_board/components/control_panel/components/data_input_panel/components/FileInput";
import Stack from "@mui/material/Stack";

export interface KeyshapeItemProps {
  display: string,
  label: string,
  requestData: FormData,
  refreshState: () => void,
}

export const KeyshapeItem: FC<KeyshapeItemProps> = ({display, label, requestData, refreshState}) => {
  const [file, setFile] = useState<File | undefined>(undefined);

  const fileChangeCallback = useCallback((file: File | undefined) => {
    if (file !== undefined) {
      requestData.set(label, file);
    } else {
      requestData.delete(label);
    }
    refreshState();
  }, [label, refreshState, requestData]);

  return (
      <ListItem sx={{backgroundColor: "#340b64CC", borderRadius: "8px", marginBottom: "16px", padding: 0}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{textAlign: 'left'}}>
            <Stack direction={"column"}>
              <Typography sx={{paddingLeft: 1}}>{display}</Typography>
              <Box>
                <FileInput icon={<ViewInArIcon/>} fileChangeCallback={fileChangeCallback} accept=".fbx" file={file} setFile={setFile}/>
                <Box sx={{flexGrow: 1}}/>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </ListItem>
  )
}